import { IMoeAddtocollectionResources } from '../modules/moe-addtocollection/moe-addtocollection.props.autogenerated';

// Language values
export const ENGLISH_LANG = 'english';
export const MAORI_LANG = 'maori';
export const ENGLISH_LANG_CODE = 'en-au';
export const MAORI_LANG_CODE = 'en-nz';
export const ENGLISH_OTHER_LANG_CODE = 'en-nz';
export const CUST_MAORI_LANG_CODE = 'mi-nz';
export const AXE_MAORI_LANG_CODE = 'mi-nz'; // Language code used for Maori for assistive technologies like screen reader

// Modules or page values
export const ERROR_OR_NOTIFICATION = 'ERROR_OR_NOTIFICATION';
export const HEADER_FOOTER = 'HEADER_FOOTER';
export const NAVIGATION = 'NAVIGATION';
export const HOME_PAGE = 'HOME_PAGE';
export const LANDING_PAGE = 'LANDING_PAGE';
export const METADATA = 'METADATA';
export const SOCIAL_SHARE = 'SOCIAL_SHARE';
export const MY_PROFILE = 'MY_PROFILE';
export const DOWNLOAD_KETE = 'DOWNLOAD_KETE';
export const COLLECTIONS = 'COLLECTIONS';
export const ADD_TO_KETE = 'ADD_TO_KETE';
export const SEARCH = 'SEARCH';
export const ACTION_BUTTONS = 'ACTION_BUTTONS';
export const COLLECTIONS_SITE_LANGUAGE = 'COLLECTIONS_SITE_LANGUAGE';
export const ADD_TO_KETE_SITE_LANGUAGE = 'ADD_TO_KETE_SITE_LANGUAGE';
export const DOWNLOAD_KETE_SITE_LANGUAGE = 'DOWNLOAD_KETE_SITE_LANGUAGE';
export const SEARCH_SITE_LANGUAGE = 'SEARCH_SITE_LANGUAGE';
export const VIDEO_PLAYER = 'VIDEO_PLAYER';

// Site Names
export const OCH = 'och';
export const TWK = 'twk';
export const KWR = 'kwr';
export const KOH = 'koh';
export const NZC = 'nzc';

// site toggles
export const MAORI_TOGGLE_PREFIX = 'mi';
export const ENGLISH_TOGGLE_PREFIX = '/mi/';

// Add to collection - resources values
export const ADD_TO_COLLECTION_RESOURCES: IMoeAddtocollectionResources = {
    resourceKey: 'resource value',
    flipperNext: 'Next',
    flipperPrevious: 'Previous',
    unknownError: 'Unexpected error occured. Please try again later.',
    unknownErrorInMaori: 'I puta he raru. Me whakamātau anō',
    emptyCollection: 'Collection is not available. Please check and try again.',
    emptyCollectionInMaori: 'Kāre te kohinga i te wātea. Me taki, ka mahi anō',
    emptyCollections: 'no collections are available at this moment',
    emptyCollectionsInMaori: 'kāre he kohinga e wātea ana i tēnei wā ',
    duplicateName: 'Same collection name already exists. Please try with different name.',
    duplicateNameInMaori: 'Kua tau kē tēnei tapanga. Whakamahia he ingoa kē.',
    duplicateResource: 'Same resource already exists in the collection.',
    duplicateResourceInMaori: 'Kua uru kē tēnā rauemi ki te kohinga',
    unAuthenticated: 'User is not authenticated. Please login create collection',
    unAuthenticatedInMaori: 'Kāre anō te kaiwhakamahi kia motuhēhēhia. Takiuru mai ki te waihanga kohinga',
    noAccountNumber: 'Customer account number missing. Please logout and login again.',
    noAccountNumberInMaori: 'Kāre he nama pūkete kiritaki. Me takiputa, ka takiuru mai anō',
    networkOffline: 'Internet is offline or it is very slow. Please check your internet connection.',
    networkOfflineInMaori: 'Kua weto te ipurangi, he pōturi rānei. Tirohia tō hononga ipurangi.',
    exceededCollectionNameLimit: 'Collection name should be less than 25 characters',
    exceededCollectionNameLimitInMaori: 'Kia iti iho i te 25 ngā pūāhua o te ingoa o tō kohinga',
    noEmptyCollectionSharing: 'Empty collection cannot be shared',
    noEmptyCollectionSharingInMaori: 'Kāre e taea te tuari kohinga piako ',
    createCOllection: 'Collection has been created successfully.',
    createCOllectionInMaori: 'Kua waihangatia he kohinga',
    addResource: 'Resource has been added to the collection successfully.',
    addResourceInMaori: 'Kua tāpirihia te rauemi ki te kohinga',
    updateCollection: 'Collection was updated successfully.',
    updateCollectionInMaori: 'Previous',
    updateCollectionName: 'Collection name has been updated successfully.',
    updateCollectionNameInMaori: 'Kua whakahoutia te ingoa o te kohinga',
    deleteCollection: 'Collection was deleted successfully.',
    deleteCollectionInMaori: 'Kua mukua te kohinga',
    deleteProduct: 'Product was deleted successfully from the collection.',
    deleteProductInMaori: 'Kua mukua te hua mai i te kohinga',
    notLoggedIn: 'Not logged in yet? Please login to use the collections',
    notLoggedInInMaori: 'Kāre anō kia takiuru mai? Me takiuru mai ki te whakamahi i ngā kohinga',
    addToCollectionText: 'Add to Collection',
    addToCollectionTextInMaori: 'Tāpirihia ki te kohinga',
    heyText: 'Hey',
    heyTextInMaori: 'Kia ora',
    addNewCollection: 'please click on + icon to create new collection',
    addNewCollectionInMaori: 'tēnā pāwhiria te atahanga + ki te hanga kohinga hou',
    newCollectionText: 'New Collection',
    newCollectionTextInMaori: 'He kohinga hou',
    createCollectionText: 'Click to create a collection',
    createCollectionTextInMaori: 'Pāwhiri ki te waihanga kohinga',
    closeText: 'Close',
    closeTextInMaori: 'Kati',
    selectResourcesHoverText: 'Please select a resource to enable the button',
    selectResourcesHoverTextInMaori: 'Tīpakohia koa he rauemi hei whakahohe i te paatene',
    createNewCollectionText: 'createNewCollectionText',
    createNewCollectionTextInMaori: 'hanga kete hou'
};

/*
  Item: Development environment setup for Language preference
  Usage: Can be used to set the language toggle and language preference values while developing and maintaining in future
  Caution: Don't change and push this file. Push this file to git only if necessary
  Values (DEVELOPMENT_LANGUAGE_TOGGLE, DEVELOPMENT_LANGUAGE_PREFERENCE): should be only 'english' or 'maori'
  Values (DEVELOPMENT_SITE_NAME): OCH or TWK or KWR or KOH or NZC
*/
export const DEVELOPMENT_LANGUAGE_TOGGLE = 'english';
export const DEVELOPMENT_LANGUAGE_PREFERENCE = 'maori';
export const DEVELOPMENT_SITE_NAME = NZC;
